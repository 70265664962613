import { Project } from '../types';

const spacenet: Project = {
  name: 'Topographic Deep Neural Networks',
  description: 'Learning topographic maps in deep convolutional neural networks',
  image: {
    src: 'topographic_dnn_2.png',
    alt: 'Rainbow color blobs'
  },
  page: 'projects/topographic_dnn'
}

const ultraHighResFMRI: Project = {
  name: 'Submillimeter Imaging of Human Visual Cortex',
  description: 'Ultra-high-resolution fMRI of human visual cortex',
  image: {
    src: 'submm_representations.png',
    alt: 'Data shown on the ventral surface of a human brain'
  },
  page: 'projects/submm_representations'
}

const pixelwiseCNN: Project = {
  name: 'CNNs for Face Classification',
  description: 'Understanding face recognition in deep neural networks',
  image: {
    src: 'cnn_face.png',
    alt: 'A colorful image synthesized to maximally excite part of an artifical neural network'
  },
  page: 'projects/cnn_face'
}

const loc: Project = {
  name: 'Object Familiarity in LOC',
  description: 'How are objects and scenes encoded in the lateral occipital complex?',
  image: {
    src: 'loc_familiarity.png',
    alt: '3D representation of a brain with red splotches indicating activity'
  },
  page: 'projects/loc_familiarity'
}

const xlVert: Project = {
  name: 'Object Recognition in Modified Line Drawings',
  description: 'Psychophysical analysis of object recognition under distinct kinds of noise',
  image: {
    src: 'vertices.png',
    alt: 'line drawing of a series of interconnected rectangles'
  },
  page: 'projects/vertices'
}
export const researchProjects: Project[] = [spacenet, ultraHighResFMRI, pixelwiseCNN, loc, xlVert];