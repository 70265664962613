import React from "react";
import { Project } from "../../types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import "./ProjectList.scss";
import { Link } from "gatsby";

interface ProjectListProps {
  projects: Project[];
  images: any;
}

function ProjectView({ project, images }: { project: Project; images: any }) {
  const { src, alt } = project.image;
  const { page, name, description, url } = project;
  const matchingImage = images.edges.find(
    (edge) => edge.node.childImageSharp.fixed.originalName === src
  );

  const img = matchingImage ? (
    <GatsbyImage
      image={getImage(matchingImage.node.childImageSharp.gatsbyImageData)}
      objectFit="scale-down"
      alt={alt}
    />
  ) : null;

  const guts = (
    <div className="project">
      <div className="project-img">{img}</div>
      <div className="project-description">
        <h3 className="title">{name}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
  return page ? (
    <Link to={page} target="_blank">
      {guts}
    </Link>
  ) : (
    <a href={url} target="_blank" rel="noreferrer">
      {guts}
    </a>
  );
}

function ProjectList({ projects, images }: ProjectListProps): JSX.Element {
  return (
    <div className="projects">
      {projects.map((project) => (
        <ProjectView project={project} images={images} />
      ))}
    </div>
  );
}

export default ProjectList;
