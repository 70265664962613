import React from "react";
import "@fontsource/lato";
import "./index.scss";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import { researchProjects } from "../data/researchProjects";
import { webProjects } from "../data/webProjects";
import { educationalProjects } from "../data/educationalProjects";
import ProjectList from "../components/ProjectList";
import { IoLogoGithub, IoLogoLinkedin, IoLogoTwitter } from "react-icons/io";
import { SiGooglescholar } from "react-icons/si";

function Home({ data }): JSX.Element {
  const allImages = data.allFile;
  return (
    <div className="home">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Eshed Margalit, PhD</title>
        <html lang="en" />
      </Helmet>

      <div className="grid">
        <StaticImage
          className="main-photo"
          src="../images/clouds_rest_sq.jpeg"
          alt="Picture of Eshed hiking"
          placeholder="tracedSVG"
          height={250}
          width={250}
        />

        {/* Top Intro Block */}
        <div className="intro">
          <h1>Eshed Margalit, PhD</h1>
          <h2>Computational Neuroscience and AI Researcher</h2>
          <p>
            I research{" "}
            <a className="section-link" onClick={() => scrollTo("#research")}>
              brain-like artificial neural networks for vision
            </a>
            , create{" "}
            <a
              className="section-link"
              onClick={() => scrollTo("#webProjects")}
            >
              online tools for scientists
            </a>
            , and share{" "}
            <a className="section-link" onClick={() => scrollTo("#education")}>
              educational resources for programming and statistics
            </a>
            .
          </p>
        </div>
      </div>

      {/* Contact Info */}
      <div className="contact">
        <div className="socials">
          <a
            aria-label="LinkedIn Icon"
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/eshed-margalit-437222a7/"
          >
            <IoLogoLinkedin size="3em" />
          </a>
          <a
            aria-label="Github Icon"
            target="_blank"
            rel="noreferrer"
            href="https://github.com/eshedmargalit"
          >
            <IoLogoGithub size="3em" />
          </a>
          <a
            aria-label="Twitter Icon"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/eshedmargalit"
          >
            <IoLogoTwitter size="3em" />
          </a>
          <a
            aria-label="Google Scholar Icon"
            target="_blank"
            rel="noreferrer"
            href="https://scholar.google.com/citations?user=ijttsicAAAAJ&hl=en&oi=ao"
          >
            <SiGooglescholar size="3em" />
          </a>
        </div>
        <Link className="cv-link" to="/cv">
          Curriculum Vitae
        </Link>
      </div>

      <div>
        <hr />
      </div>

      {/* Project Sections */}
      <div className="sections">
        <div className="section" id="research">
          <h2>Research</h2>
          <p>
            I&apos;m a computational neuroscientist working at the intersection
            of deep learning, primate visual neuroscience, human fMRI, and
            cognitive neuroscience. My thesis advisors were Profs. Dan Yamins
            and Kalanit Grill-Spector at Stanford University. My thesis focused
            on building deep neural network models that better match the
            function, structure, and development of visual cortex.
          </p>
          <ProjectList projects={researchProjects} images={allImages} />
        </div>
        <hr />
        <div className="section" id="webProjects">
          <h2>Web Portfolio</h2>
          <p>
            I create online tools that make information easier to find, store,
            access, and share.
          </p>
          <ProjectList projects={webProjects} images={allImages} />
        </div>
        <hr />
        <div className="section" id="education">
          <h2>Educational Resources</h2>
          <p>
            These are some publicly available resources I’ve developed in
            various teaching roles. Please feel free to use and share them!
          </p>
          <ProjectList projects={educationalProjects} images={allImages} />
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 100, height: 100, placeholder: BLURRED)
            fixed {
              originalName
            }
          }
        }
      }
    }
  }
`;

export default Home;
