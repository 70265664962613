import { Project } from '../types';

const dpCalc: Project = {
  name: 'd\' Calculator',
  description: 'Signal detection theory intro and interactive calculator',
  image: {
    src: 'dprime.png',
    alt: 'Stylized letter d with an apostrophe'
  },
  page: 'dprime_calculator'

}

const cnjcx: Project = {
  name: 'CNJCx: Practical Python',
  description: 'Homepage for CNJCx mini-course teaching command line and python basics',
  image: {
    src: 'python.png',
    alt: 'Python logo'
  },
  url: 'https://stanford-cnjc.github.io/#/CNJCx'
}

const gradSchoolInfo: Project = {
  name: 'Demystifying Grad School Q&A',
  description: 'Questions and candid answers about applying to PhD programs',
  image: {
    src: 'apps.png',
    alt: 'photograph of somebody writing in a notebook'
  },
  url: 'http://vpnl.stanford.edu/#/Information'
}

export const educationalProjects: Project[] = [dpCalc, cnjcx, gradSchoolInfo];