import { Project } from '../types';

const paperAWeek: Project = {
  name: 'Paper a Week',
  description: 'All-in-one solution for taking notes on papers',
  image: {
    src: 'paw.png',
    alt: 'A paper airplane, the Paper a Week logo'
  },
  page: 'projects/paperaweek'
}

const gaborJetWeb: Project = {
  name: 'Interactive Gabor Filterbank Demo',
  description: 'Introduction to the Gabor-jet Model',
  image: {
    src: 'gjw.png',
    alt: 'A 3D modeled face with overlaid Gabor kernels'
  },
  url: 'https://geon.usc.edu/GJW'
}

const vpnlSite: Project = {
  name: 'VPNL Lab Website',
  description: 'Lab website for Stanford Vision and Perception Neuroscience Lab',
  image: {
    src: 'vpnl.png',
    alt: 'Logo for the Stanford VPNL'
  },
  url: 'http://vpnl.stanford.edu'
}

const cnjcSite: Project = {
  name: 'Stanford CNJC Website',
  description: 'Homepage for the Stanford Computational Neuroscience Journal Club',
  image: {
    src: 'mbct_graphic_square.png',
    alt: 'Logo for the Stanford MBCT'
  },
  url: 'https://stanford-cnjc.github.io/#/'
}


export const webProjects: Project[] = [paperAWeek, gaborJetWeb, vpnlSite, cnjcSite];